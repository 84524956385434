#about{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}
#about .section{
    border-radius: 20px;
    padding: 0px 30px;
}
.piloni{
    background-color: #FFF;
}
.cine-suntem{
    background-color: #F2C75C;
}
.valori{
    background-color: #C63527;
}
.misiune{
    background-color: #4A773C;
}
.despre-dimi{
    background-color: #008C95;
}
#about .section .acc-head{
    position: relative;
    padding: 8px 0 8px 30px;
    font-family: 'Degular';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}
#about .section .acc-head::before{
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    background-image: url('../images/acc-arrrow.svg');
    background-size: 17px 12px;
    background-repeat: no-repeat;
    background-position: center left;
    transition: all 0.5s ease;
    width: 17px;
    height: 12px;

}
#about .section.open .acc-head::before{
    transform: rotate(180deg);
    transition: all 0.5s ease;
}
#about .section .acc-content{
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;
}
.color-black{
    color: #000 !important;
}
.color-white{
    color: #FFF !important;
}
#about .section .acc-content h2{
    font-family: 'Degular';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 935px;
}

#about .section .acc-content p{
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    max-width: 935px;
}
#about .section.piloni .acc-content p:not(:last-child){
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
}
#about .section.cine-suntem .acc-content h2,
#about .section.valori .acc-content h2,
#about .section.misiune .acc-content h2,
#about .section.despre-dimi .acc-content h2{
    margin: 0 0 25px 0;
}

#about .section.valori div.color-white{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    row-gap: 20px;
}
#about .section.valori div.color-white .values .value{
    background-color: #000;
    border-radius: 10px;
    padding: 11px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    color: #FFF;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 141.176% */
    text-transform: uppercase;
}
#about .section.valori div.color-white .values .value svg{
    margin-right: 10px;
}
#about .section.valori div.color-white .text{
    width: 100%;
}
#about .section.open .acc-content{
    max-height: 1500px;
    overflow: hidden;
    transition: all 0.5s ease-in;
}
@media (min-width: 990px){
    #about{
        width: 100vw;
        height: 100vh;
        flex-direction: row;
        padding: 0;
        position: fixed;
    }
    #about .section{
        border-radius: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        width: 68%;
        height: 100%;
        position: absolute;
        transition: all 0.5s ease-in;
    }
    #about .section.open{
        width: 68%;
    }
    #about .section .acc-head{
        width: calc(8vw - 32px);
        padding: 20px 16px;
        height: calc(100% - 40px);
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
    }
    #about .section .acc-head span{
        width: 39px;
        color: #000;
        font-family: 'Degular';
        font-size: 30px;
        font-weight: 600;
        line-height: normal;
        transform: rotate(-90deg);
        transform-origin: 50% 0;
        white-space: nowrap;
        position: absolute;
        bottom: 30px;
        left: 0;
    }
    #about .section .acc-head::before{
        display: none;
    }
    #about .section .acc-content{
        padding-top: 20px;
        width: 60vw;
        height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #about .section.open .acc-content{
        width: 60vw;
    }
    #about .section.valori div.color-white{
        column-gap: 50px;
    }
    #about .section.valori div.color-white .values .value{
        font-size: 17px;
    }
    #about .section.valori div.color-white .text{
        width: 49%;
    }
    .piloni{
        left: 60%;
        z-index: 1;
    }
    .cine-suntem{
        left: 68%;
        z-index: 2;
        transition: left 0.9s ease-in;
    }
    .valori{
        left: 76%;
        z-index: 3;
        transition: all 0.3s ease-in;
    }
    .misiune{
        left: 84%;
        z-index: 4;
        transition: all 0.3s ease-in;
    }
    .despre-dimi{
        left: 92%;
        z-index: 5;
    }
    .piloni.open{
        left: 0;
    }
    .cine-suntem.open{
        left: 8%;
        transition: left 0.9s ease-in;
    }
    .valori.open{
        left: 16%;
        transition: all 0.3s ease-in;
    }
    .misiune.open{
        left: 24%;
        transition: all 0.3s ease-in;
    }
    .despre-dimi.open{
        left: 32%;
        transition: all 0.3s ease-in;
    }
    #about .section .acc-content>div{
        display: flex;
        flex-wrap: wrap;
        padding-right: 8vw;
    }
    #about .section .acc-content h2{
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        padding-right: 20px;
        margin: 0 0 10px 0;
        width: 100%;
    }
    #about .section.cine-suntem .acc-content h2,
    #about .section.valori .acc-content h2,
    #about .section.misiune .acc-content h2,
    #about .section.despre-dimi .acc-content h2{
        margin: 0 0 24px 0;
    }
    #about .section.misiune .acc-content p,
    #about .section.despre-dimi .acc-content p.quote-author{
        margin: 0;
        text-align: right;
        display: block;
        width: 100%;
    }
    #about .section.despre-dimi .acc-content p.quote-author {
        font-family: 'Inter';
        width: 100%;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 16px;
        margin: 0 0 40px 0;
    }
    #about .section.misiune .acc-content p span,
    #about .section.despre-dimi .acc-content p.quote-author span{
        max-width: 577px;
        display: block;
        float: right;
        text-align: left;
    }
    #about .section .acc-content p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
    }
}
.about-intro{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-intro img{
    width: 72%;
    height: auto;
}