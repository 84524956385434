body{
  margin: 0;
}
#contact{
  /*background-image: url('../images/contact-bg_mobile.png');*/
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: auto;
  min-height: calc(100vh - 177px);
  margin-top: -98px;
  display: flex;
  align-items: center;
  position:relative;
}
#contact .contact-container{
  display: flex;
  align-items: center;
  padding: 98px 16px 0 16px;
  flex-wrap: wrap;
}
#contact h1{
  color: #FFF;
  font-family: 'Degular';
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  width: 100%;

}
.form-container{
  padding: 0;
  width: calc(100%);
  max-width: 632px;
  margin: 30px 0;
}
.form-container input{
  width: calc(100% - 40px);
  border-radius: 10px;
  background: #F2F2F2;
  color: #000;
  padding: 12px 20px;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}
.form-container textarea{
  width: calc(100% - 40px);
  padding: 12px 20px;
  border-radius: 10px;
  background: #F2F2F2;
  height: 118px;
}
.form-container p{
  color: #FFF;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
@media (min-width: 768px){
  #contact{
    margin-top: 0;
  }
  #contact .contact-container{
    padding-top: 168px;
  }
  .form-container{
    position: absolute;
    bottom: 0;
  }
}
@media (min-width: 990px){
  #contact{
    /*background-image: url('../images/contact-bg.png');*/
    column-gap: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 700px;
    height: calc(100vh - 83px);
  }
  #contact .contact-container{
    padding-top: 0;
    width: calc(100% - 40px);
  }
  #contact h1{
    color: #FFF;
    font-family: 'Degular';
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    line-height: 100px;
    margin: 0;
    width: 50%;
    max-width: 679px;
  }
  .form-container{
    bottom: 0;
    right: 20px;
    margin-bottom: 0;
  }
  .form-container p{
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.eyes-container{
  width: 100%;
  text-align: center;
  position: relative;
}
.eyes-container .nose{
  width: 9.2vw;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
}
.eyes-container>a{
  color: #FFF;
  text-transform: uppercase;
  font-family: 'Inter';
  font-weight: 700;
  display: block;
}
.eyes {
  width: 100%;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 60px;

}

.eye {
  width: 61px;
  height: 79px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(30deg);
  overflow: hidden;

}

.pupil {
  width: 52px;
  height: 52px;
  border-radius: 101px;
  background-color: #008C95;
  position: relative;
  left: -10px;
  top: -5px;
}

@media (min-width: 768px){
  .eyes-container .nose{
    top: 100px;
    width: 9.2vw;
  }
  .eyes-container>a img{
    width: 50%;
    height: auto;
  }
  .eyes {
    column-gap: 250px;
    height: 16.25vw;
  }
  .eye {
    width:10vw;
    height: 13vw;
  }
  .pupil {
    width: 10vw;
    height: 10vw;
    top: 0;
    left: 0;
  }
}