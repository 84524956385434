.home-s1{
    padding: 0 16px 16px 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
    row-gap: 7px;
    column-gap: 7px;
}
.bun-simt{
  width: 100%;
  background-color: #FFF;
  border-radius: 10px;
  /* padding: 20px 27px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 152px 0 202px 0;
}
.bun-simt>p{
  color: #000;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  text-decoration-line: underline;
  text-align: left;
  width: 100%;
}
.bun-simt:hover>p{
  text-decoration: underline;
}
.bun-simt>img,
.bun-simt>svg{
  width: 100%;
  height: auto
}
.home-s1 .marquee {
  width: 100%;
  position: absolute;
  padding-top: 10px;
  border-top: 1px solid #000;
  bottom: 36px;
  left: 0;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.home-s1 .marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 6s linear infinite;
  animation-play-state: running;
}

.home-s1 .marquee span {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-right: 20vw;
  white-space: nowrap;
  font-family: "Degular";
}

.home-s1 .marquee:hover .marquee__inner {
  animation-play-state: running;
}

@keyframes marquee {
  0% {
      transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
      transform: translate3d(var(--move-final), 0, 0);
  }
}
.home-s1 .support-s1{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  row-gap: 7px;
  column-gap: 7px;
}

.home-s1 .support-s1 .support-article{
  display: flex;
  width: 100%;
  position: relative;
}
.home-s1 .support-s1 .support-article>div{
  display: none;
  width: calc(100% - 46px);
  height: calc(100% - 46px);
  background-color: rgba(0,0,0,0.6);
  align-items: flex-end;
  justify-content: flex-start;
  padding: 23px;
  color: #FFF;
  position: absolute;
  font-family: 'Inter';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.home-s1 .support-s1 .support-article picture{
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}
.home-s1 .support-s1 .support-article img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.home-s1 .support-s1 .support-menu{
  position: relative;
  width: 100%;
  min-height: 300px;
}
.home-s1 .support-s1 .support-menu .tag{
  color: #000;
  font-family: 'Degular';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 35px;
  border-radius: 10px;
  padding: 10px;
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
}
.home-s1 .support-s1 .support-menu .tag:hover{
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px
}
.home-s1 .support-s1 .support-menu .tag::before{
  content: '';
  display: block;
  position: absolute;
  background-color: #000;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  top: 24px;
  left: 20px;
}
.home-s1 .support-s1 .support-menu .tag.white{
  background-color: #FFF;
  z-index: 9;
  top: 0;
}
.home-s1 .support-s1 .support-menu .tag.yellow{
  background-color: #F2C75C;
  z-index: 10;
  top: 45px;
}
.home-s1 .support-s1 .support-menu .tag.red{
  background-color: #C63527;
  top: 93px;
  z-index: 11;
}
.home-s1 .support-s1 .support-menu .tag.green{
  background-color: #4A773C;
  z-index: 12;
  top: 140px;
}
.home-s1 .support-s1 .support-menu .tag.blue{
  background-color: #008C95;
  z-index: 12;
  top: 185px;
}
.about{
  color: #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.about:hover{
  text-decoration: underline;
  text-underline-offset: 3px;
}
.home-s1 .support-s1 .support-image{
  width: 100%;
  background-position: top center;
  min-height: 458px;
  border-radius: 10px;
  position: relative;
}
.home-s1 .support-s1 .support-image picture{
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}
.home-s1 .support-s1 .support-image picture img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.home-s1 .support-s1 .support-image>div{
  display: none;
  width: calc(100% - 46px);
  height: calc(100% - 46px);
  background-color: rgba(0,0,0,0.6);
  align-items: flex-end;
  justify-content: flex-start;
  padding: 23px;
  color: #FFF;
  font-family: 'Inter';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (min-width: 768px){
  .home-s1{
      padding: 20px;
      width: calc(100% - 40px);
      margin-top: 0px;
  }

}

@media (min-width: 990px){
  .bun-simt{
    width: calc(57% - 57.5px);
    padding: 150px 0 196px 0;
  }
  .bun-simt>p{
    max-width: 464px;
  }
  .home-s1 .support-s1{
    width: calc(43% - 3.5px);
  }
  .home-s1 .support-s1 .support-empty{
    width: calc(39% - 3.5px);
  }
  .home-s1 .support-s1 .support-article{
    width: calc(61% - 3.5px);
  }
  .home-s1 .support-s1 .support-menu{
    width: calc(61% - 3.5px);
  }
  .home-s1 .support-s1 .support-image{
    width: calc(39% - 3.5px);
  }
  .home-s1 .support-s1 .support-image:hover>div{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
  }
  .home-s1 .support-s1 .support-article:hover>div{
    display: flex;
  }
  .bun-simt>img,
  .bun-simt>svg{
    max-width: 464px;

  }
  .home-s1 .marquee span {
    font-size: 70px;
  }
  .home-s1 .support-s1 .support-menu .tag{
    font-size: 25px;
    padding: 20px 30px 28px 30px;
    width: calc(100% - 60px);
  }
  .home-s1 .support-s1 .support-menu .tag::before{
    width: 20px;
    height: 20px;
    top: 30px;
    left: 30px;
  }
  .home-s1 .support-s1 .support-menu .tag.white{
    z-index: 9;
  }
  .home-s1 .support-s1 .support-menu .tag.yellow{
    z-index: 10;
    top: 65px;
  }
  .home-s1 .support-s1 .support-menu .tag.red{
    top: 140px;
  }
  .home-s1 .support-s1 .support-menu .tag.green{
    top: 210px;
  }
  .home-s1 .support-s1 .support-menu .tag.blue{
    top: 280px;
  }
  .about{
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 600;
    left: 30px;
  }
}

.home-s2{
  padding: 0 16px;
  width: calc(100% - 32px);
  position: relative;
  margin-bottom: 50px;
}
.home-s2 img{
  width: 100%;
  height: auto;
  display: block;
}
.home-s2 .sensus-intreaba-link{
  position: absolute;
  width: 61%;
  height: 32.45%;
  right: 16px;
  bottom: 0;
  cursor: pointer;
}
.home-s2 .sensus-intreaba-link .headline{
  position: absolute;
  top: 17px;
  left: 15px;
  color: #000;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.home-s2 .sensus-intreaba-link .title{
  color: #000;
  font-family: 'Degular';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  left: 15px;
  top: 37%;
}
.home-s2  .despre-link{
  display: none;
}
@media (min-width: 768px){
  .home-s2{
    padding: 0 20px;
    width: calc(100% - 40px);
    margin-bottom: 65px;
  }
  .home-s2 .sensus-intreaba-link{
    position: absolute;
    width: 81.4%;
    height: 32.8%;
    right: 20px;
    bottom: 0;
    cursor: pointer;
  }

  .home-s2 .sensus-intreaba-link .title{
    right: 38px;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 990px){
  .home-s2  .despre-link{
    position: absolute;
    width: 64.68%;
    height: 66.7%;
    display: block;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .home-s2  .despre-link>div{
    width: 37%;
    max-width: 464px;
    position: absolute;
    top: 47px;
    left: 23.6%;
  }
  .home-s2  .despre-link>div img{
    display: block;
    margin-bottom: 13px;
  }
  .home-s2 .despre-link>div span{
    color: #000;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .home-s2 .despre-link>div span:hover{
    text-decoration: underline;

  }
  .home-s2 .sensus-intreaba-link .headline{
    font-size: 30px;
    left: 27px;
  }
  .home-s2 .sensus-intreaba-link .title{
    font-size: 70px;
    line-height: 70px;
    right: 38px;
    top: 50%;
    left: auto;
    transform: translateY(-50%);
  }
}
.despre-dimi-home{
  display: flex;
  position: relative;
}
.despre-dimi-home span{
  position: absolute;
  bottom: 17px;
  left: 19px;
  color: #FFF;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.aa-DetachedOverlay,
.aa-DetachedContainer{
  background-color: #000 !important;
}
.aa-Panel{
  background-color: #000 !important;
  color: #FFF !important;
  border: 1px solid #FFF !important;
}
.aa-Panel .aa-Item .aa-ItemContent{
  color: #FFF !important;
  background-color: #000 !important;
}
.aa-Panel .aa-Panel .aa-PanelLayout{
  background-color: #000 !important;
  color: #FFF !important;
}
.aa-Panel .aa-Item{
  color: #FFF !important;
}
.aa-Panel .aa-Item[aria-selected='true']{
  background-color: #000 !important;
  color: #FFF !important
}
.aa-Panel .aa-Item:hover .aa-ItemContent{
  background-color: #000 !important;
  color: #FFF !important;
}
.aa-Panel .aa-Item:hover .aa-ItemContent{
  background-color: #000;
  color: #FFF !important;
}
.aa-Panel .aa-Item .aa-ItemContent a{
  font-family: 'Inter-Regular';
  color: #FFF !important;
}
.aa-powered-by{
  display: none !important;
}