.sensus-intreaba{
  background-color: #FFF;
  min-height: calc(100vh - 176px);
  display: flex;
}
.sensus-intreaba .carousel{
  width: calc(100% - 32px);
  display: block;
  column-gap: 8px;
  padding: 0 16px;
  overflow: hidden;
}
.sensus-intreaba .carousel:focus-visible{
  outline: 0;
}
.sensus-intreaba .carousel .cell{
  width: 100%;
  margin-right: 8px;
}
.sensus-intreaba .carousel .cell .text-container{
  border-radius: 10px;
  padding: 30px 20px;
}
.sensus-intreaba .carousel .cell .text-container p{
  color: inherit;
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 15px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid inherit;
}
.sensus-intreaba .carousel .cell .text-container p:last-child{
  margin: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.sensus-intreaba .carousel .cell img{
  width: 100%;
  height: auto;
}
.sensus-intreaba h1{
  color: #000;
  background-color: #FFF;
  font-family: 'Degular';
  font-size: 100px /*145px*/;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: center;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  padding: 20px 16px;
  width: calc(100% - 32px);
  margin: 0;
  text-wrap: balance;
}
@media (min-width: 990px){
  .sensus-intreaba .carousel .cell{
    width: 464px;
    perspective: 1000px;
    margin-bottom: 20px;
  }
  .sensus-intreaba{
    padding-top: 167px;
  }
  .sensus-intreaba h1{
    position: relative;
    font-family: 'Degular';
    font-size: 170px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .sensus-intreaba .carousel .cell img{
    transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    display: block;
  }
  .sensus-intreaba .carousel .cell .text-container p{
    font-size: 17px;
    line-height: 24px;
  }
  .sensus-intreaba .carousel .cell .text-container{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: calc(100% - 60px);
    transition: transform 0.8s;
    transform: rotateY(-180deg);
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  .sensus-intreaba .carousel .cell:hover .text-container{
    transform: rotateY(0deg);

  }
  .sensus-intreaba .carousel .cell:hover img{
    transform: rotateY(180deg);
  }
}

.flickity-viewport:focus-visible{
  outline: 0;
}