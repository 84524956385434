.header{
  position: relative;
  z-index: 1;
}
.article-body{
  background-color: #FFF;
  padding-bottom: 130px;
}
.outer-container{
  color: #FFF;
  font-family: 'Degular';
  font-weight: 600;
}
.category-date{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
.outer-container .category-date .category-name{
  text-transform: capitalize;
  font-family: 'Degular';
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  display: inline-block;
}
.outer-container .category-date .separator{
  padding: 0 8px;
  display: inline-block;
}
.outer-container .category-date  .blog-post-meta{
  color: #FFF;
  margin: 0;
  font-family: 'Degular';
  display: inline-block;
}
.outer-container h1{
  font-family: 'Degular';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 10px 0;
}
.outer-container .article-subtitle{
  font-family: 'Inter';
  font-size: 13px;
  line-height: normal;
  margin: 0 0 24px 0;
}
.article-main-image{
  width: 100%;
  height: auto;
  display: block;
}
.article-body .paragraph-wrapper .post-part{
  margin: 30px auto 0 auto;
  display: block;
  max-width: 935px;
}
.article-body .paragraph-wrapper .post-part h2{
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.article-body .paragraph-wrapper .post-part .block-img{
  display: block;
  margin: 35px 0 30px 0;
}
.article-body .paragraph-wrapper .post-part p{
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.article-body .quote-wrapper .post-part .block-quotation{
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 8px;
  margin: 30px 0 0 48px;
  border-left: 1px solid #707070;
  max-width: calc(100% - 60px);
}
.article-body  .image-wrapper .full-width-image{
  max-width: 100%;
  padding: 0;
  margin: 30px 0 0 0;
}
.articole-similare{
  background-color: #000;
  padding: 30px 20px 50px 20px;
}
.article-list{
  background-color: #FFF;
  padding: 30px 20px 130px 20px;
  display: flex;
  flex-wrap: wrap;
}
.article-list .category-list{
  order: 2;
  width: 100%;
  margin-bottom: 48px;
}
.article-list .category-list .cat{
  color: #FFF;
  font-family: 'Inter';
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  padding: 6px 25px;
  display: inline-block;
  margin: 0 7px 10px 0;
  border-radius: 20px
}
.articole-similare>h2,
.article-list>h2{
  color: #FFF;
  font-family: 'Degular';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 40px 0;
  text-align: center;
}
.article-list>h2{
  color: #000;
  width: 100%;
  order: 1;
}
.articole-similare .blog-main,
.article-list .blog-main{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.article-list .blog-main{
  order: 3;
}
.articole-similare .blog-main .blog-post,
.article-list .blog-main .blog-post{
  width: 100%;

}
.articole-similare .blog-main .blog-post a,
.article-list .blog-main .blog-post a{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  color: #FFF;
  column-gap: 5px;
}
.article-list .blog-main .blog-post a{
  color: #000;
}
.articole-similare .blog-main .blog-post a>div,
.article-list .blog-main .blog-post a>div{
  width: calc(100% - 20px);
  flex: 1;
  padding: 0 10px;
}
.articole-similare .blog-main .blog-post a .category,
.article-list .blog-main .blog-post a .category{
  font-family: 'Degular';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.articole-similare .blog-main .blog-post a h2,
.article-list .blog-main .blog-post a h2{
  color: #FFF;
  font-family: 'Degular';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 5px 0;
}
.article-list .blog-main .blog-post a h2{
  color: #000;
}
.articole-similare .blog-main .blog-post a .excerpt,
.article-list .blog-main .blog-post a .excerpt{
  margin: 0;
  font-family: 'Inter';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.articole-similare .blog-main .blog-post img,
.article-list .blog-main .blog-post img{
  width: 30%;
  height: auto;
  border-radius: 10px;
}
@media (min-width: 990px){
  .articole-similare .blog-main .blog-post a>div,
  .article-list .blog-main .blog-post a>div{
    padding: 0 20px;
    width: calc(100% - 40px);
  }
  .article-body{
    padding-bottom: 260px;
  }
  .category-date{
    margin-bottom: 40px;
  }
  .outer-container .category-date .category-name,
  .outer-container .category-date  .blog-post-meta {
    font-size: 17px;
  }
  .outer-container h1{
    font-size: 100px;
    line-height: 100px;
    margin: 0 0 40px 0;
  }
  .outer-container .article-subtitle{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 80px;
  }
  .article-body .paragraph-wrapper .post-part h2{
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    margin:  0;
  }
  .article-body .paragraph-wrapper .post-part .block-img {
    margin: 60px 0;
  }
  .article-body .paragraph-wrapper .post-part p{
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
  }
  .article-body .quote-wrapper .post-part .block-quotation{
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    padding-left: 27px;
    margin: 60px 0 30px 150px;
    max-width: calc(100% - 180px);
  }
  .article-body  .image-wrapper .full-width-image{
    max-width: 100%;
    padding: 0;
    margin: 60px 0 75px 0;
  }
  .articole-similare{
    padding: 55px 20px 75px 20px;
  }
  .article-list{
    display: flex;
    padding-bottom: 260px;
  }
  .articole-similare>h2,
  .article-list>h2{
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 935px;
    margin: 0 auto 75px auto;
    order: unset;
  }
  .articole-similare .blog-main,
  .article-list .blog-main{
    column-gap: 8px;

  }
  .article-list .blog-main{
    width: 100%;
    order: unset;
    flex-wrap: wrap;
  }
  .articole-similare .blog-main .blog-post{
    width: calc(33% - 6px);
  }
  .article-list .blog-main .blog-post{
    width: calc(33.33333% - 5.4px);
  }
  .articole-similare .blog-main .blog-post a,
  .article-list .blog-main .blog-post a{
    flex-direction: column;
  }
  .articole-similare .blog-main .blog-post img,
  .article-list .blog-main .blog-post img{
    width: 100%;
    height: auto;
    margin: 0 0 20px 0;
  }
  .articole-similare .blog-main .blog-post a .category,
  .article-list .blog-main .blog-post a .category{
    font-size: 17px;
    font-weight: 600;
    line-height: normal;
  }
  .articole-similare .blog-main .blog-post a h2,
  .article-list .blog-main .blog-post a h2{
    font-size: 45px;
    line-height: 45px;
    margin: 0 0 20px 0;
  }
  .articole-similare .blog-main .blog-post a .excerpt,
  .article-list .blog-main .blog-post a .excerpt{
    font-family: 'Inter';
    font-size: 17px;
    line-height: 24px;
  }
  .article-list .category-list{
    width: calc(25% - 150px);
    padding-left: 150px;
    margin-bottom: 0;
    order: unset;
    max-width: 360px;
  }
}


.banner-liquid{
  background-color: #008C95;
  padding: 36px 16px 0 16px;
  position: relative;
  min-height: 650px;
}
.banner-liquid .page-width{
  margin: 0 auto;
  position: sticky;
  top: 50px;
}
.banner-liquid h2{
  color: #FFF;
  font-family: 'Degular';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
}
.banner-liquid img{
  width:100%;
  height: 60%;
  object-fit: cover;
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
}
@media (min-width: 990px){
  .banner-liquid{
    padding: 165px 20px 180px;
  }
  .banner-liquid .page-width{
    max-width: 1520px;
    margin: 0 auto;
    position: sticky;
    top: 50px;
  }
  .banner-liquid h2{
    font-size: 70px;
    line-height: 70px;
    max-width: 1245px;
  }
  .banner-liquid img{
    position: absolute;
    display: block;
    height: auto;
    left: -20px;
    bottom: 0;
    width: calc(100% + 20px)
  }
}
@media (min-width: 1440px){
  .banner-liquid{
    padding: 165px 20px 250px;
  }
}
@media (min-width: 1600px){
  .banner-liquid{
    padding: 165px 20px 350px;
  }
}
.pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  background: #FFF;
  order: 4;
  position: relative;
}
.pagination .next{
  position: relative;
  right: 0;
  top: 0;
  padding: 10px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.pagination .prev{
  position: relative;
  left: 0;
  top: 0;
  padding: 10px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.pagination .page{
  color: #000;
  width: 40px;
  text-align: center;
  font-family: 'Inter';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 141.176% */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}
.pagination .page.active,
.pagination .page:hover{
  border-radius: 100%;
  background-color: #EEE;
}


.top-blog{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 16px;
  margin-bottom: 50px;
}
.top-blog .main-art,
.top-blog .second-art{
  width: 100%;
  margin-bottom: 24px;
}
.top-blog .second-art .founder-dimi{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}
.top-blog .second-art .founder-dimi img{
  width: 92px;
  height: 92px;
  margin-right: 17px;
}
.top-blog .second-art .founder-dimi p{
  width: 100%;
  flex: 1;
  color: #FFF;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.top-blog .second-art .founder-dimi p a{
  color: #FFF;
  font-family: 'Inter';
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.top-blog .main-art .blog-post,
.top-blog .second-art .blog-post{
  width: 100%;
  margin-bottom: 0;
}
.top-blog .main-art .blog-post a,
.top-blog .second-art .blog-post a{
  width: 100%;
  display: flex;
  align-items: center;
  color: #FFF;
  flex-wrap: wrap;

}
.top-blog .main-art .blog-post a,
.top-blog .second-art .blog-post a{
  color: #FFF;
}
.top-blog .main-art .blog-post a>div,
.top-blog .second-art .blog-post a>div{

}
.top-blog .main-art .blog-post a .category,
.top-blog .second-art .blog-post a .category{
  font-family: 'Degular';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-transform: uppercase;
}
.top-blog .main-art .blog-post a h2,
.top-blog .second-art .blog-post a h2{
  color: #FFF;
  font-family: 'Degular';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 10px 0;
}
.top-blog .main-art .blog-post a:hover h2,
.top-blog .second-art .blog-post a:hover h2,
.top-blog .main-art .blog-post a:hover .excerpt{
  text-decoration: underline;
}
.top-blog .main-art .blog-post a .excerpt,
.top-blog .second-art .blog-post a .excerpt{
  margin: 0;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.top-blog .main-art .blog-post img,
.top-blog .second-art .blog-post img{
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}
@media (min-width: 990px){
  .top-blog{
    column-gap: 23px;
    margin-bottom: 100px;
  }
  .top-blog .main-art .blog-post a>div,
  .top-blog .second-art .blog-post a>div{
    width: calc(100% - 20px);
    padding: 0 10px;
  }
  .top-blog .second-art .founder-dimi{
    margin-top: 60px;
  }
  .top-blog .second-art .founder-dimi img{
    margin-right: 23px;
  }
  .top-blog .second-art .founder-dimi p{
    font-size: 17px;
    line-height: 24px;
  }
  .top-blog .second-art .founder-dimi p a{
    font-size: 12px;
    line-height: 24px;
  }
  .top-blog .second-art{
    width: calc(25% - 16px);
  }
  .top-blog .main-art{
    width: calc(50% - 16px);
  }

  .top-blog .main-art .blog-post a .category,
  .top-blog .second-art .blog-post a .category{
    font-size: 17px;
  }
  .top-blog .main-art .blog-post a h2,
  .top-blog .second-art .blog-post a h2{
    font-size: 38px;
    line-height: 45px;
    margin: 0 0 20px 0;
  }
  .top-blog .main-art .blog-post a .excerpt{
    font-size: 17px;
    line-height: 24px;
  }
  .top-blog .second-art .blog-post a .excerpt{
    display: none;
  }
}

.teaser-articles{
  width: 100%;
}
.teaser-articles>a{
  color: #FFF;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.teaser-articles .teaser-post a{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid #FFF;
}
.teaser-articles .teaser-post a>img{
  width: 104px;
  height: auto;
  border-radius: 5px;
}
.teaser-articles .teaser-post a>div{
  width: 100%;
  flex: 1;
}
.teaser-articles .teaser-post a .category{
  color: #FFF;
  font-family: 'Degular';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}
.teaser-articles .teaser-post a h2{
  color: #FFF;
  font-family: 'Degular';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
@media (min-width: 990px){
  .teaser-articles {
    width: calc(25% - 15px);
  }
  .teaser-articles>a{
    font-size: 12px;
    line-height: 24px;
  }
  .teaser-articles .teaser-post a{
    margin-bottom: 29px;
    padding-bottom: 29px;
  }
  .teaser-articles .teaser-post a>img{
    width: 150px;

  }
  .teaser-articles .teaser-post a .category{
    font-size: 17px;
    line-height: normal;
    padding: 0 16px;
  }
  .teaser-articles .teaser-post a:hover h2{
    text-decoration: underline;
  }
  .teaser-articles .teaser-post a h2{
    font-size: 26px;
    line-height: 30px;
    margin: 0;
    padding: 0 16px;
  }
}

.white.newsletter-subscription{
  width: 100%;
  color: #000;
  order: 1;
  background-color: #FFF;
}
.white.newsletter-subscription .marquee {
  width: 100%;
  position: relative;
  padding-top: 10px;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.white.newsletter-subscription .marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 6s linear infinite;
  animation-play-state: running;
}

.white.newsletter-subscription .marquee span {
  color: #000;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  font-family: "Degular";
  padding-right: 10px;
}
.white.newsletter-subscription .marquee span b{
  font-family: 'Inter';
}
.white.newsletter-subscription .marquee:hover .marquee__inner {
  animation-play-state: running;
}
.white.newsletter-subscription div[data-testid="form-row"]{
  flex-wrap: wrap;
}
.white.newsletter-subscription div[data-testid="form-component"]{
  width: 100% !important;
  flex: auto !important;
}
.white.newsletter-subscription .klaviyo-form>div{
  min-height: 30px !important;
}
.white.newsletter-subscription .klaviyo-form>div>form>div{
  min-height: 0;
}
.white .klaviyo-form>div>div:first-child{
  color: #000 !important;
}

.white.newsletter-subscription input{
  background-color: #F2F2F2 !important;
  border-color: #F2F2F2 !important;
  color: #000 !important;
}
.white.newsletter-subscription button{
  background-color: #000 !important;
  color: #FFF !important;
  text-align: center !important;
  font-family: 'Inter' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  height: 50px !important;
  padding: 0 50px !important;
  border-radius: 10px !important;
}
.white.newsletter-subscription ::placeholder {
  color: #000 !important;
  opacity: 1 !important; /* Firefox */
}

.white.newsletter-subscription ::-ms-input-placeholder { /* Edge 12 -18 */
  color: #000 !important;
}
.white.newsletter-subscription div[data-testid="form-component"] [id^="rich-text-"]{
  color: #666;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (min-width: 768px){
  .white.newsletter-subscription{
    order: 0;
  }
  .white.newsletter-subscription div[data-testid="form-component"]{
    width: 100% !important;
    flex: 1 0 0px !important;
  }
}
.social-container{
  max-width: 935px;
  width: 100%;
  margin: 20px auto 50px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
}
.social-share{
  color: #000;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
}